/* eslint-disable class-methods-use-this */
import API from '@/service/api'

class AuthService {
  login(user) {
    // bruce@wayne.com | bruce1234!
    return API()
      .post('/auth/local', {
        identifier: user.identifier,
        password: user.password,
      })
      .then(response => {
        // Handle success.
        console.log('Well done! Logged In')
        // console.log('response.data', response.data)
        // console.log('User profile', response.data.user)
        // console.log('User token', response.data.jwt)

        if (response.data.jwt) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
      .catch(error => {
        // Handle error.
        console.log('A login error occurred:', error.response)
      })
  }

  logout() {
    localStorage.removeItem('user')
  }
}
export default new AuthService()
