import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  feedbackEntries: [],
}

const mutations = {
  UPDATE_FEEDBACK_ENTRIES(state, payload) {
    state.feedbackEntries = payload
  },
}

const actions = {
  getFeedbackEntries({ commit }) {
    return API()
      .get('/feedbacks', { headers: authHeader() })
      .then(response => {
        // console.log(response.data)
        console.log('getFeedbackEntries OK')
        commit('UPDATE_FEEDBACK_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getFeedbackEntries')
        console.log(error.response.data)
        return error
      })
  },

  addFeedback({ commit }, payload) {
    return API()
      .post('/feedbacks', payload, { headers: authHeader() })
      .then(response => {
        console.log('addFeedback OK')
        commit('UPDATE_FEEDBACK_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addFeedback')
        console.log(error)
        return error
      })
  },

  deleteFeedback({ commit }, id) {
    return API()
      .delete(`/feedbacks/${id}`, { headers: authHeader() })
      .then(response => {
        console.log('deleteFeedback OK')
        commit('UPDATE_FEEDBACK_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: deleteFeedback')
        console.log(error)
        return error
      })
  },

  updateFeedback({ commit }, payload) {
    return (
      API()
        // .put(`/locations/${payload.id}`, payload)
        .put(`/feedbacks/${payload.id}`, payload, { headers: authHeader() })
        .then(response => {
          console.log('updateFeedback OK')
          commit('UPDATE_FEEDBACK_ENTRIES', response.data)
          return response.data
        })
        .catch(error => {
          console.log('Error: updateLocation')
          console.log(error)
          return error
        })
    )
  },
}

const getters = {
  feedbackEntries: state => state.feedbackEntries,
  feedbackById: state => id => {
    if (!Array.isArray(state.feedbackEntries)) {
      console.warn(
        'state.feedbackEntries is not an array:',
        state.feedbackEntries
      )
      return undefined
    }

    return state.feedbackEntries.find(feedbackEntry => feedbackEntry.id === id)
  },
}

const locationsModule = {
  state,
  mutations,
  actions,
  getters,
}

export default locationsModule
