import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  activitiesEntries: [],
  activityTypesEntries: [],
}

const mutations = {
  ADD_ACTIVITY_ENTRY(state, newActivity) {
    state.activitiesEntries.push(newActivity)
  },
  UPDATE_ACTIVITIES_ENTRIES(state, payload) {
    state.activitiesEntries = payload
  },
  UPDATE_ACTIVITY_TYPES_ENTRIES(state, payload) {
    state.activityTypesEntries = payload
  },
  REMOVE_ACTIVITY_ENTRY(state, activityId) {
    state.activitiesEntries = state.activitiesEntries.filter(
      activity => activity.id !== activityId
    )
  },
  UPDATE_ACTIVITY_ENTRY(state, updatedActivity) {
    const index = state.activitiesEntries.findIndex(
      activity => activity.id === updatedActivity.id
    )
    if (index !== -1) {
      state.activitiesEntries.splice(index, 1, updatedActivity)
    }
  },
}

const actions = {
  getActivitiesEntries({ commit }) {
    return API()
      .get('/activities', { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ACTIVITIES_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getActivitiesEntries')
        console.log(error)
        return error
      })
  },
  getActivityTypesEntries({ commit }) {
    return API()
      .get('/activity-types', { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ACTIVITY_TYPES_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getActivityTypesEntries')
        console.log(error.response.data)
        return error
      })
  },
  addActivity({ commit }, payload) {
    return API()
      .post('/activities', payload, { headers: authHeader() })
      .then(response => {
        commit('ADD_ACTIVITY_ENTRY', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addActivity')
        console.log(error.response.data)
        return error
      })
  },
  deleteActivity({ commit }, activityId) {
    return API()
      .delete(`/activities/${activityId}`, { headers: authHeader() })
      .then(response => {
        commit('REMOVE_ACTIVITY_ENTRY', activityId)
        return response.data
      })
      .catch(error => {
        console.log('Error: deleteActivity')
        console.log(error.response.data)
        return error
      })
  },

  editActivity({ commit }, payload) {
    return API()
      .put(`/activities/${payload.id}`, payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ACTIVITY_ENTRY', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: editActivity')
        console.log(error.response.data)
        return error
      })
  },
}

const getters = {
  activitiesEntries: state => state.activitiesEntries,
  activitiesById: state => id =>
    state.activitiesEntries.find(activityEntry => activityEntry.id === id),
  getActivityTypes: state => state.activityTypesEntries,
}

const activityModule = {
  state,
  mutations,
  actions,
  getters,
}

export default activityModule
