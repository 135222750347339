import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  filesEntries: [],
}

const mutations = {
  UPDATE_FILES_ENTRIES(state, payload) {
    state.filesEntries = payload
  },
}

const actions = {
  getFilesEntries({ commit }) {
    return API()
      .get('/upload/files', { headers: authHeader() })
      .then(response => {
        commit('UPDATE_FILES_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getFilesEntries')
        console.log(error)
        return error
      })
  },
  addFile({ commit }, payload, config) {
    return API()
      .post('/upload', payload, config, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_FILES_ENTRIES', response.data)
        console.log('SUCCESS!!')
        return response.data
      })
      .catch(error => {
        console.log('Error: addFile')
        console.log(error)
        return error
      })
  },
  deleteFile({ commit }, id) {
    return API()
      .delete(`/upload/files/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_FILES_ENTRIES', response.data)
        console.log('OK: deleteFile')
        return response.data
      })
      .catch(error => {
        console.log('Error: deleteFile')
        console.log(error.response.data)
        return error
      })
  },
}

const getters = {
  filesEntries: state => state.filesEntries,
}

const uploadModule = {
  state,
  mutations,
  actions,
  getters,
}

export default uploadModule
