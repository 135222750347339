import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  shopUploadEntries: [],
  shopUploadEntry: {},
}

const mutations = {
  UPDATE_SHOPUPLOAD_ENTRIES(state, payload) {
    state.shopUploadEntries = payload
  },

  UPDATE_SHOPUPLOAD_ENTRY(state, payload) {
    state.shopUploadEntry = payload
  },
}

const actions = {
  getShopUploadEntries({ commit }) {
    return API()
      .get('/shop-uploads', { headers: authHeader() })
      .then(response => {
        commit('UPDATE_SHOPUPLOAD_ENTRIES', response.data)
      })
  },
  getShopUploadEntriesById({ commit }, id) {
    return API()
      .get(`/shop-uploads/${id}`, { headers: authHeader() })

      .then(response => {
        commit('UPDATE_SHOPUPLOAD_ENTRY', response.data)
      })
  },

  addShopUploadEntries({ commit }, payload) {
    return API()
      .post('/shop-uploads', payload, { headers: authHeader() })
      .then(response => {
        console.log('SUCCESS!!')
        commit('UPDATE_SHOPUPLOAD_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addAttachment')
        console.log(error.response.data)
        return error
      })
  },

  deleteShopUploadEntries({ commit }, id) {
    API()
      .delete(`/shop-uploads/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_SHOPUPLOAD_ENTRIES', response.data).catch(error => {
          console.error(error.response.data)
        })
      })
  },
}

const getters = {
  shopUploadEntries: state => state.shopUploadEntries,
  shopUploadById: state => id =>
    state.shopUploadEntries.find(
      shopUploadEntries => shopUploadEntries.id === id
    ),
  // Check if any entry has a non-null offer
  hasOffer: state =>
    state.shopUploadEntries.some(
      entry => entry.offer !== null && entry.offer !== undefined
    ),
}

const attachmentModule = {
  state,
  mutations,
  actions,
  getters,
}

export default attachmentModule
