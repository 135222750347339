import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  commentEntries: [],
}

const mutations = {
  UPDATE_COMMENT_ENTRIES(state, payload) {
    state.commentEntries = payload
  },
}

const actions = {
  getCommentsEntries({ commit }) {
    return API()
      .get('/comments', { headers: authHeader() })
      .then(response => {
        commit('UPDATE_COMMENT_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getCommentEntries')
        console.log(error)
        return error
      })
  },
  addComment({ commit }, payload) {
    return API()
      .post('/comments', payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_COMMENT_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addComment')
        console.log(error)
        return error
      })
  },
  deleteComment({ commit }, payload) {
    return API()
      .delete(`/comments/${payload}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_COMMENT_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: deleteComment')
        console.log(error)
        return error
      })
  },
  updateComment({ commit }, payload) {
    return API()
      .put(`/comments/${payload.id}`, payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_COMMENT_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: updateComment')
        console.log(error)
        return error
      })
  },
}

const getters = {
  commentEntries: state => state.commentEntries,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
