import API from '@/service/api'
import authHeader from '@/service/auth-header'
import moment from 'moment'
import 'moment/locale/de'

const state = {
  orderItemsEntries: [],
  orderItemEntry: {},
}

const mutations = {
  UPDATE_ORDERITEMS_ENTRIES(state, payload) {
    state.orderItemsEntries = payload
  },
  UPDATE_ORDERITEM_ENTRY(state, payload) {
    state.orderItemEntry = payload
  },
}

const actions = {
  getOrderItemsEntries({ commit }) {
    API()
      .get('/order-items', { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ORDERITEMS_ENTRIES', response.data)
      })
  },
  // eslint-disable-next-line no-unused-vars
  getOrderItemById({ commit }, id) {
    API()
      .get(`/order-items/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ORDERITEM_ENTRY', response.data)
      })
  },
  addOrderItem({ commit }, payload) {
    return API()
      .post('/order-items', payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ORDERITEMS_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addOrderItem')
        console.log(error)
        return error
      })
  },
  deleteOrderItem({ commit }, id) {
    return API()
      .delete(`/order-items/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ORDERITEMS_ENTRIES', response.data)
        return response.data
      })
      .catch(err => {
        console.error(err)
      })
  },
  // eslint-disable-next-line no-unused-vars
  updateOrderItem({ COMMIT, dispatch }, payload) {
    return API()
      .put(`/order-items/${payload.id}`, payload, { headers: authHeader() })
      .then(response => {
        console.log('order items update')
        console.log(response.data)
        dispatch('getOrderItemsEntries')
        return response.data
      })
      .catch(error => {
        console.log('Error: updateOrderItem')
        console.log(error)
        return error
      })
  },
}

const getters = {
  orderItemsEntries: state => state.orderItemsEntries,
  // eslint-disable-next-line spaced-comment
  orderItem: state => state.orderItemEntry,
  // eslint-disable-next-line spaced-comment
  orderItemById: state => id =>
    state.orderItemsEntries.find(orderItemEntry => orderItemEntry.id === id),

  // Get total sum of all order items wich dont have a moveoutdate
  orderItemsTotalSum: state => {
    let sum = 0
    state.orderItemsEntries.forEach(orderItem => {
      if (orderItem.moveOutDate === null) {
        sum += orderItem.price
      }
    })
    return sum
  },

  // Get total sum of all orderitems from orderitem.rentStart to date orderitem.moveOutDate
  // eslint-disable-next-line spaced-comment

  // Loop through all orderitems and sum the price of the orderitems from rentsart to moveoutdate
  orderItemsTotalSumFromStartToMoveOutDate: state => {
    let sum = 0
    state.orderItemsEntries.forEach(orderItem => {
      if (orderItem.moveOutDate !== null) {
        // Get month count from startrent to moveoutdate
        const monthCount = moment(orderItem.moveOutDate).diff(
          orderItem.rentStart,
          'months',
          true
        )

        sum += monthCount * orderItem.price
      }
    })
    return sum
  },

  // Get the an array with the monthly revenue form the last 12 months. The first element is the oldest month. The last element is the current month. Consider also entries with moveoutdate.
  orderItemsMonthlyRevenue: state => {
    moment.locale('de') // Setze die Lokalisierung auf Deutsch
    const monthlyRevenue = {}
    for (let i = 12; i >= 1; i -= 1) {
      const date = moment(Date.now()).subtract(i, 'months')
      const monthKey = date.format('YYYY-MM')
      const monthLabel = date.format('MMM', 'de-DE') // Monat im deutschen Format
      let sum = 0
      let count = 0
      const orderItemsByMonth = state.orderItemsEntries.filter(
        orderItem =>
          // eslint-disable-next-line operator-linebreak
          moment(orderItem.rentStart).format('YYYY-MM') <= monthKey &&
          // eslint-disable-next-line operator-linebreak
          (orderItem.moveOutDate === null ||
            moment(orderItem.moveOutDate).format('YYYY-MM') >= monthKey)
      )
      orderItemsByMonth.forEach(orderItem => {
        const numericPrice = Number(orderItem.price)
        if (Number.isNaN(numericPrice)) {
          console.warn(`Invalid price for order item: ${orderItem.price}`)
        } else {
          sum += numericPrice
          count += 1
        }
      })
      monthlyRevenue[monthLabel] = { sum, count }
    }
    return monthlyRevenue
  },

  // Loop through all orderitems and sum the price of the orderitems from rentsart to now
  orderItemsTotalSumFromStartToNow: state => {
    let sum = 0
    state.orderItemsEntries.forEach(orderItem => {
      // eslint-disable-next-line operator-linebreak
      const monthCount =
        moment(Date.now()).diff(orderItem.rentStart, 'months', true) + 1 // +1 because we want to count the current month too
      sum += parseInt(monthCount, 10) * orderItem.price
    })
    return sum
  },

  // orderItemsTotalSumFromTo: state => (rentStart, moveOutDate) => {
  //   let sum = 0
  //   state.orderItemsEntries.forEach(orderItem => {
  //     if (orderItem.moveOutDate !== null) {
  //       if (orderItem.rentStart >= rentStart && orderItem.moveOutDate <= moveOutDate) {
  //         sum += orderItem.price
  //       }
  //     }
  //   })
  //   return sum
  // }
}

const orderItemModule = {
  state,
  mutations,
  actions,
  getters,
}

export default orderItemModule
