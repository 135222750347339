import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  inquiriesEntries: [],
}

const mutations = {
  UPDATE_INQUIRIES_ENTRIES(state, payload) {
    state.inquiriesEntries = payload
  },
}

const actions = {
  getInquiriesEntries({ commit }) {
    return API()
      .get('/inquiries', { headers: authHeader() })
      .then(response => {
        // console.log(response.data)
        console.log('getInquiriesEntries OK')
        commit('UPDATE_INQUIRIES_ENTRIES', response.data)
      })
      .catch(error => {
        console.log('Error: getInquiriesEntries')
        console.log(error)
        return error
      })
  },
  addInquiry({ commit }, payload) {
    return API()
      .post('/inquiries', payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_INQUIRIES_ENTRIES', response.data)
      })
      .catch(error => {
        console.log('Error: addInquiry')
        console.log(error)
        return error
      })
  },

  deleteInquiry({ commit }, id) {
    return API()
      .delete(`/inquiries/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_INQUIRIES_ENTRIES', response.data)
      })
      .catch(error => {
        console.log('Error: deleteInquiry')
        console.log(error)
        return error
      })
  },
  // eslint-disable-next-line no-unused-vars
  updateInquiry({ commit }, payload) {
    return (
      API()
        // .put(`/locations/${payload.id}`, payload)
        .put(`/inquiries/${payload.id}`, payload, { headers: authHeader() })
        .then(response => {
          console.log('updateInquiry OK')
          console.log(response.data)
          // commit('UPDATE_INQUIRIES_ENTRIES', response.data)
        })
        .catch(error => {
          console.log('Error: updateInquiry')
          console.log(error)
          return error
        })
    )
  },
}

const getters = {
  inquiriesAllEntries: state => state.inquiriesEntries,

  inquiriesAllAcceptedEntries: state =>
    state.inquiriesEntries.filter(
      inquiriyEntry => inquiriyEntry.qualification === null
    ),

  inquiriesAllOpenEntries: state =>
    state.inquiriesEntries.filter(
      inquiriyEntry => inquiriyEntry.status === 'offer'
    ),

  inquiriesAcceptedEntries: state =>
    state.inquiriesEntries.filter(
      /* eslint-disable operator-linebreak */
      inquiriyEntry =>
        inquiriyEntry?.qualification === 'Akzeptiert' &&
        inquiriyEntry?.offers.length === 0
    ),
  inquiryById: state => id =>
    state.inquiriesEntries.find(inquiriyEntry => inquiriyEntry.id === id),
}

const inquiryModule = {
  state,
  mutations,
  actions,
  getters,
}

export default inquiryModule
