import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  offerEntries: [],
  offerEntry: {},
}

const mutations = {
  UPDATE_OFFER_ENTRIES(state, payload) {
    state.offerEntries = payload
  },
  UPDATE_OFFER_ENTRY(state, payload) {
    state.offerEntry = payload
  },
}

const actions = {
  getOfferEntries({ commit }) {
    return API()
      .get('/offers', { headers: authHeader() })
      .then(response => {
        console.log('getOfferEntries OK')
        commit('UPDATE_OFFER_ENTRIES', response.data)
      })
      .catch(error => {
        console.log('Error: getOfferEntries')
        console.log(error)
        return error
      })
  },
  addOffer({ commit }, payload) {
    return API()
      .post('/offers', payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_OFFER_ENTRY', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addOffer')
        console.log(error)
        return error
      })
  },
  deleteOffer({ commit }, id) {
    API()
      .delete(`/offers/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_OFFER_ENTRIES', response.data).catch(error => {
          console.error(error)
        })
      })
  },
  updateOffer({ commit }, payload) {
    return (
      API()
        // .put(`/locations/${payload.id}`, payload)
        .put(`/offers/${payload.id}`, payload, { headers: authHeader() })
        .then(response => {
          commit('UPDATE_OFFER_ENTRIES', response.data)
        })
        .catch(error => {
          console.error(error)
        })
    )
  },
}

const getters = {
  offerEntries: state => state.offerEntries,

  offerByID: state => id =>
    state.offerEntries.find(offerEntry => offerEntry.id === id),

  // Get total sum of all storages within all offers
  getTotalSumOfAllStoragesAndOffers: state => {
    let sum = 0
    for (let index = 0; index < state.offerEntries.length; index += 1) {
      if (state.offerEntries[index].isActive) {
        for (
          let index2 = 0;
          index2 < state.offerEntries[index].storages.length;
          index2 += 1
        ) {
          sum += state.offerEntries[index].storages[index2].price
        }
      }
    }
    return sum
  },

  // Get offers where isActive is true
  getActiveOffers: state =>
    state.offerEntries.filter(offerEntry => offerEntry.isActive),
}

const orderModule = {
  state,
  mutations,
  actions,
  getters,
}

export default orderModule
