const state = {
  countOfAllStorages: 123,
}

const mutations = {

}

const actions = {
 
}

const getters = {
  getCountOfAllStorages: state => state.countOfAllStorages,

}

const dashboardsModule = {
  state,
  mutations,
  actions,
  getters,
}

export default dashboardsModule
