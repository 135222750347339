import API from '@/service/api'
import authHeader from '@/service/auth-header'
// import UserService from '@/service/customer.service'

const state = {
  customerEntries: [],
  customerEntry: {},
}

const mutations = {
  UPDATE_CUSTOMER_ENTRIES(state, payload) {
    state.customerEntries = payload
  },
  UPDATE_CUSTOMER_ENTRY(state, payload) {
    state.customerEntry = payload
  },
  RESET_CUSTOMER_ENTRIES(state) {
    state.customerEntries = []
  },
}

const actions = {
  getCustomerEntries({ commit }) {
    return API()
      .get('/customers', { headers: authHeader() })
      .then(response => {
        console.log('getCustomerEntries OK')
        commit('UPDATE_CUSTOMER_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getCustomerEntries')
        console.log(error)
        return error
      })
  },

  // eslint-disable-next-line no-unused-vars
  getCustomerById({ commit }, id) {
    return API()
      .get(`/customers/${id}`, { headers: authHeader() })
      .then(response => {
        console.log('getCustomerById OK')
        commit('UPDATE_CUSTOMER_ENTRY', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getCustomerById')
        console.log(error)
        return error
      })
  },

  addCustomer({ commit }, payload) {
    return API()
      .post('/customers', payload, { headers: authHeader() })
      .then(response => {
        console.log('addCustomer OK')
        commit('UPDATE_CUSTOMER_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addCustomer')
        console.log(error)
        return error
      })
  },

  deleteCustomer({ commit }, id) {
    return API()
      .delete(`/customers/${id}`, { headers: authHeader() })
      .then(response => {
        console.log('deleteCustomer OK')
        commit('UPDATE_CUSTOMER_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: deleteCustomer')
        console.log(error)
        return error
      })
  },

  updateCustomer({ commit }, payload) {
    return API()
      .put(`/customers/${payload.id}`, payload, { headers: authHeader() })
      .then(response => {
        console.log('updateCustomer OK')
        commit('UPDATE_CUSTOMER_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: updateCustomer')
        console.log(error)
        return error
      })
  },
}

const getters = {
  customerEntries: state => state.customerEntries,
  // eslint-disable-next-line spaced-comment
  customerEntry: state => state.customerEntry,
  // eslint-disable-next-line spaced-comment
  customerById: state => id =>
    state.customerEntries.find(customerEntry => customerEntry.id === id),

  countOfCompaniesCustomers: state =>
    state.customerEntries.filter(
      customerEntry => customerEntry.organizationName !== null
    ),
  customerExistByEmail: state => email =>
    state.customerEntries.find(customerEntry => customerEntry.email === email),
}

const customerModule = {
  state,
  mutations,
  actions,
  getters,
}

export default customerModule
