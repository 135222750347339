import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  locationsEntries: [],
}

const mutations = {
  UPDATE_LOCATIONS_ENTRIES(state, payload) {
    state.locationsEntries = payload
  },

  ADD_LOCATIONS_ENTRIES(state, payload) {
    console.log(payload)
    state.locationsEntries.push(payload)
    console.log(state.locationsEntries)
  },

  REMOVE_LOCATIONS_ENTRIES(state, payload) {
    // console.log(payload)
    const i = state.locationsEntries.map(item => item.id).indexOf(payload)
    state.locationsEntries.splice(i, 1)
  },
}

const actions = {
  getLocationsEntries({ commit }) {
    return API()
      .get('/locations', { headers: authHeader() })
      .then(response => {
        // console.log(response.data)
        console.log('getLocationEntries OK')
        commit('UPDATE_LOCATIONS_ENTRIES', response.data)
      }).catch(error => {
        console.log('Error: getLocationEntries')
        console.log(error.response.data)
        return error
      })
  },

  addLocation({ commit }, payload) {
   return API()
      .post('/locations', payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_LOCATIONS_ENTRIES', response.data)
      }).catch(error => {
        console.log('Error: addLocation')
        console.log(error)
        return error
      })
  },

  deleteLocation({ commit }, id) {
    return API()
      .delete(`/locations/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_LOCATIONS_ENTRIES', response.data)
      }).catch(error => {
        console.log('Error: deleteLocation')
        console.log(error)
        return error
      })
  },

  updateLocation({ commit }, payload) {
    return API()
      // .put(`/locations/${payload.id}`, payload)
      .put(`/locations/${payload.id}`, payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_LOCATIONS_ENTRIES', response.data)
      }).catch(error => {
        console.log('Error: updateLocation')
        console.log(error)
        return error
      })
  },
}

const getters = {
  locationsEntries: state => state.locationsEntries,
  locationById: state => id =>
    state.locationsEntries.find(locationsEntry => locationsEntry.id === id),
}

const locationsModule = {
  state,
  mutations,
  actions,
  getters,
}

export default locationsModule
