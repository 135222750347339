import API from '@/service/api'
import authHeader from '@/service/auth-header'
// import UserService from '@/service/customer.service'

const state = {
  userEntries: [],
  userEntry: {},
  meEntry: {},
  permissionRoles: [],
}

const mutations = {
  UPDATE_USER_ENTRIES(state, payload) {
    state.userEntries = payload
  },
  UPDATE_USER_ENTRY(state, payload) {
    state.userEntry = payload
  },
  UPDATE_ME_ENTRY(state, payload) {
    state.meEntry = payload
  },
  UPDATE_ROLES_ENTRIES(state, payload) {
    state.permissionRoles = payload
  },
  RESET_CUSTOMER_ENTRIES(state) {
    state.userEntries = []
  },
}

const actions = {
  getUserEntries({ commit }) {
    return API()
      .get('/users', { headers: authHeader() })
      .then(response => {
        console.log('getUsersEntries OK')
        commit('UPDATE_USER_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getUserEntries')
        console.log(error)
        return error
      })
  },
  getMe({ commit }) {
    return API()
      .get('/users/me', { headers: authHeader() })
      .then(response => {
        console.log('getMeEntry OK')
        commit('UPDATE_ME_ENTRY', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getMeEntry')
        console.log(error)
        return error
      })
  },
  getPermissionRoles({ commit }) {
    return API()
      .get('/users-permissions/roles', { headers: authHeader() })
      .then(response => {
        console.log('getPermissionRoles OK')
        commit('UPDATE_ROLES_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getPermissionRoles')
        console.log(error)
        return error
      })
  },
  getUserById({ commit }, id) {
    return API()
      .get(`/users/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_USER_ENTRY', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getUserById')
        console.log(error)
        return error
      })
  },
  // eslint-disable-next-line no-unused-vars
  addUser({ commit, dispatch }, payload) {
    return API()
      .post('/auth/local/register', payload, { headers: authHeader() })
      .then(response => {
        // dispatch('getUserEntries')
        commit('UPDATE_USER_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addUser')
        console.log(error)
        return error
      })
  },
  // eslint-disable-next-line no-unused-vars
  deleteUser({ commit, dispatch }, id) {
    return API()
      .delete(`/users/${id}`, { headers: authHeader() })
      .then(response => {
        // dispatch('getUserEntries')
        commit('UPDATE_USER_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: deleteUser')
        console.log(error)
        return error
      })
  },
  updateUser({ commit }, payload) {
    return API()
      .put(`/users/${payload.id}`, payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_USER_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: updateCustomer')
        console.log(error)
        return error
      })
  },
}

const getters = {
  userEntries: state => state.userEntries,
  userEntry: state => state.userEntry,
  meEntry: state => state.meEntry,
  permissionRoles: state => state.permissionRoles,
  userById: state => id =>
    state.userEntries.find(userEntry => userEntry.id === id),
}

const userModule = {
  state,
  mutations,
  actions,
  getters,
}

export default userModule
