import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  storageEntries: [],
  storageTypesEntries: [],
}

const mutations = {
  UPDATE_STORAGE_ENTRIES(state, payload) {
    state.storageEntries = payload
  },
  UPDATE_STORAGE_TYPES_ENTRIES(state, payload) {
    state.storageTypesEntries = payload
  },
}

const actions = {
  getStorageEntries({ commit }) {
    return API()
      .get('/storages', { headers: authHeader() })
      .then(response => {
        // console.log(response.data)
        console.log('getStorageEntries OK')
        commit('UPDATE_STORAGE_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getStorageEntries')
        console.log(error)
        return error
      })
  },

  addStorage({ commit }, payload) {
    return API()
      .post('/storages', payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_STORAGE_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addStorage')
        console.log(error.response.data)
        return error
      })
  },
  deleteStorage({ commit }, id) {
    return API()
      .delete(`/storages/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_STORAGE_ENTRIES', response.data)
        console.log('OK: deleteStorage')
        return response.data
      })
      .catch(error => {
        console.log('Error: deleteStorage')
        console.log(error.response.data)
        return error
      })
  },
  updateStorage({ commit }, payload) {
    return (
      API()
        // .put(`/locations/${payload.id}`, payload)
        .put(`/storages/${payload.id}`, payload, { headers: authHeader() })
        .then(response => {
          commit('UPDATE_STORAGE_ENTRIES', response.data)
          return response.data
        })
        .catch(error => {
          console.log('Error: updateStorage')
          console.error(error.response.data)
          return error
        })
    )
  },

  getStorageTypesEntries({ commit }) {
    return API()
      .get('/storage-types', { headers: authHeader() })
      .then(response => {
        commit('UPDATE_STORAGE_TYPES_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getStorageTypesEntries')
        console.log(error.response.data)
        return error
      })
  },

  addStorageType({ commit }, payload) {
    return API()
      .post('/storage-types', payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_STORAGE_TYPES_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addStorageType')
        console.log(error.response.data)
        return error
      })
  },

  deleteStorageType({ commit }, id) {
    return API()
      .delete(`/storage-types/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_STORAGE_TYPES_ENTRIES', response.data)
        console.log('OK: deleteStorageType')
        return response.data
      })
      .catch(error => {
        console.log('Error: deleteStorageType')
        console.log(error.response.data)
        return error
      })
  },

  updateStorageType({ commit }, payload) {
    return API()
      .put(`/storage-types/${payload.id}`, payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_STORAGE_TYPES_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: updateStorageType')
        console.error(error.response.data)
        return error
      })
  },
}

const getters = {
  storageEntries: state => state.storageEntries,
  storageById: state => id =>
    state.storageEntries.find(storageEntry => storageEntry.id === id),
  getStorageTypes: state => state.storageTypesEntries,
  getStorageTypesById: state => id =>
    state.storageTypesEntries.find(storageType => storageType.id === id),
  getAllStorageFromLocationId: state => id =>
    state.storageEntries
      .filter(storageEntry => storageEntry.location.id === id)
      .sort((a, b) => b.id - a.id),
  getAllStorageFromOfferId: state => id =>
    state.storageEntries
      .filter(storageEntry => storageEntry.offer.id === id)
      .sort((a, b) => b.id - a.id),
}

const storageModule = {
  state,
  mutations,
  actions,
  getters,
}

export default storageModule
