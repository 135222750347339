import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import activity from './activity'
import app from './app'
import appConfig from './app-config'
import attachment from './attachment'
import customer from './customer'
import inquiry from './inquiry'
import location from './location'
import offer from './offer'
import order from './order'
import orderItem from './orderItem'
import storage from './storage'
import upload from './upload'
import verticalMenu from './vertical-menu'
// import auth from './auth'
import user from './user'
// eslint-disable-next-line import/no-named-as-default
import authentication from './authentication'
import comment from './comment'
import dashboard from './dashboard'
import feedback from './feedback'
import shopuploads from './shop-uploads'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    customer,
    order,
    offer,
    orderItem,
    storage,
    location,
    inquiry,
    activity,
    attachment,
    upload,
    authentication,
    user,
    dashboard,
    feedback,
    comment,
    shopuploads,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState()],
})
