import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      props: true,
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/dashboard/BasicDashboard.vue'),
      props: true,
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/inquiries-list',
      name: 'inquiries-list',
      component: () => import('@/views/pages/inquiries/list/InquiriesList.vue'),
      props: true,
      meta: {
        pageTitle: 'Anfragen',
        breadcrumb: [
          {
            text: 'Anfragen',
            active: true,
          },
        ],
      },
    },
    {
      path: '/inquiries-view/:id',
      name: 'inquiries-view',
      component: () => import('@/views/pages/inquiries/view/InquiriesView.vue'),
      props: true,
      meta: {
        pageTitle: 'Anfragedetails',
        breadcrumb: [
          {
            text: 'Anfragedetails',
            active: true,
          },
        ],
      },
    },
    {
      path: '/offers-list',
      name: 'offers-list',
      component: () => import('@/views/pages/offers/list/OffersList.vue'),
      props: true,
      meta: {
        pageTitle: 'Angebot',
        breadcrumb: [
          {
            text: 'Angebot',
            active: true,
          },
        ],
      },
    },
    {
      path: '/offers-view/:id',
      name: 'offers-view',
      component: () => import('@/views/pages/offers/view/OffersView.vue'),
      props: true,
      meta: {
        pageTitle: 'Angebotdetails',
        breadcrumb: [
          {
            text: 'Angebotdetails',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customers-list',
      name: 'customers-list',
      component: () => import('@/views/pages/customers/list/CustomersList.vue'),
      props: true,
      meta: {
        pageTitle: 'Customers',
        breadcrumb: [
          {
            text: 'Customers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customers-view/:id',
      name: 'customers-view',
      component: () => import('@/views/pages/customers/view/CustomersView.vue'),
      props: true,
      meta: {
        pageTitle: 'Kundendetails',
        breadcrumb: [
          {
            text: 'Kundendetails',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders-list',
      name: 'orders-list',
      component: () => import('@/views/pages/orders/list/OrdersList.vue'),
      props: true,
      meta: {
        pageTitle: 'Orders',
        breadcrumb: [
          {
            text: 'Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders-view/:id',
      name: 'orders-view',
      component: () => import('@/views/pages/orders/view/OrdersView.vue'),
      props: true,
      meta: {
        pageTitle: 'Buchungsdetails',
        breadcrumb: [
          {
            text: 'Buchungsdetails',
            active: true,
          },
        ],
      },
    },
    {
      path: '/storages-list',
      name: 'storages-list',
      component: () => import('@/views/pages/storages/list/StoragesList.vue'),
      props: true,
      meta: {
        pageTitle: 'Lager',
        breadcrumb: [
          {
            text: 'Lager',
            active: true,
          },
        ],
      },
    },
    {
      path: '/storages-view/:id',
      name: 'storages-view',
      component: () => import('@/views/pages/storages/view/StoragesView.vue'),
      props: true,
      meta: {
        pageTitle: 'Lagerdetails',
        breadcrumb: [
          {
            text: 'Lagerdetails',
            active: true,
          },
        ],
      },
    },
    // Locations
    {
      path: '/locations-list',
      name: 'locations-list',
      component: () => import('@/views/pages/locations/list/LocationsList.vue'),
      props: true,
      meta: {
        pageTitle: 'Standorte',
        breadcrumb: [
          {
            text: 'Standorte',
            active: true,
          },
        ],
      },
    },
    {
      path: '/locations-view/:id',
      name: 'locations-view',
      component: () => import('@/views/pages/locations/view/LocationsView.vue'),
      props: true,
      meta: {
        pageTitle: 'Standortdetails',
        breadcrumb: [
          {
            text: 'Standortdetails',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-offer',
      name: 'Direktangebot',
      component: () => import('@/views/pages/createOffers/CreateOffer.vue'),
      props: true,
      meta: {
        pageTitle: 'Angebot erstellen',
        breadcrumb: [
          {
            text: 'Angebot erstellen',
            active: true,
          },
        ],
      },
    },
    {
      path: '/booking',
      name: 'Direktbuchung',
      component: () => import('@/views/pages/movein/Movein.vue'),
      props: true,
      meta: {
        pageTitle: 'Direktbuchung',
        breadcrumb: [
          {
            text: 'Direktbuchung',
            active: true,
          },
        ],
      },
    },
    {
      path: '/booking-summery',
      name: 'buchung',
      component: () => import('@/views/pages/movein/BookingSummery.vue'),
      props: true,
      meta: {
        pageTitle: 'Buchung',
        breadcrumb: [
          {
            text: 'Buchung',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users-list',
      name: 'users-list',
      component: () => import('@/views/pages/user/list/UserList.vue'),
      meta: {
        pageTitle: 'Benutzer',
        breadcrumb: [
          {
            text: 'Benutzer',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users-view/:id',
      name: 'users-view',
      component: () => import('@/views/pages/user/view/UsersView.vue'),
      props: true,
      meta: {
        pageTitle: 'Benutzer',
        breadcrumb: [
          {
            text: 'Benutzer',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/pages/settings/Settings.vue'),
      props: true,
      meta: {
        pageTitle: 'Einstellungen',
        breadcrumb: [
          {
            text: 'Einstellungen',
            active: true,
          },
        ],
      },
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import('@/views/pages/feedback/Feedback.vue'),
      props: true,
      meta: {
        pageTitle: 'Feedback',
        breadcrumb: [
          {
            text: 'Feedback',
            active: true,
          },
        ],
      },
    },
    {
      path: '/feedback-view/:id',
      name: 'feedback-view',
      component: () => import('@/views/pages/feedback/view/FeedbackView.vue'),
      props: true,
      meta: {
        pageTitle: 'Feedback',
        breadcrumb: [
          {
            text: 'Feedback',
            active: true,
          },
        ],
      },
    },
    {
      path: '/shopuploads-list',
      name: 'shopuploads-list',
      component: () =>
        import('@/views/pages/shopUploads/list/ShopUploadsList.vue'),
      props: true,
      meta: {
        pageTitle: 'Shop Uploads',
        breadcrumb: [
          {
            text: 'Shop Uploads',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })

//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }

//   return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
