import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  orderEntries: [],
  orderEntry: {},
}

const mutations = {
  UPDATE_ORDER_ENTRIES(state, payload) {
    state.orderEntries = payload
  },
  UPDATE_ORDER_ENTRY(state, payload) {
    state.orderEntry = payload
  },
}

const actions = {
  getOrderEntries({ commit }) {
    API()
      .get('/orders', { headers: authHeader() })
      .then(response => {
        // console.log(response)
        commit('UPDATE_ORDER_ENTRIES', response.data)
      })
  },
  addOrder({ commit }, payload) {
    return API()
      .post('/orders', payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ORDER_ENTRY', response.data)
        return response.data
      })
      .catch(error => {
        console.error(
          'Error in addOrder with payload',
          payload,
          error.response ? error.response.data : error
        )
        throw error
      })
  },
  deleteOrder({ commit }, id) {
    API()
      .delete(`/orders/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ORDER_ENTRIES', response.data).catch(error => {
          console.error(error)
        })
      })
  },
  updateOrder({ commit }, payload) {
    API()
      // .put(`/locations/${payload.id}`, payload)
      .put(`/orders/${payload.id}`, payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ORDER_ENTRIES', response.data).catch(err => {
          console.error(err)
        })
      })
  },
  orderByOrderId: state => id =>
    state.orderEntries.filter(orderEntry => orderEntry.id === id),
}

const getters = {
  orderEntries: state => state.orderEntries,

  orderbyID: state => id =>
    state.orderEntries.filter(orderEntry => orderEntry.id === id),

  orderByCustomerId: state => id => {
    const orders = state.orderEntries.filter(
      orderEntry => orderEntry.customer?.id === id
    )
    const found = orders.length > 0
    if (found) {
      return orders
    }
    return null
  },

  // eslint-disable-next-line arrow-body-style
  getTotalSumByOrderId: state => id => {
    const order = state.orderEntries.find(
      orderEntry => orderEntry.id === id
    ).order_items
    let sum = 0
    for (let index = 0; index < order.length; index += 1) {
      sum += order[index].price
    }
    return sum
  },
}

const orderModule = {
  state,
  mutations,
  actions,
  getters,
}

export default orderModule
