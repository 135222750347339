export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'))
  if (user && user.jwt) {
    // console.log(user)
    // console.log('authHeader: user.jwt is not null')
    return { Authorization: `Bearer ${user.jwt}` }
  }

  console.log('authHeader: user is null')
  return {}
}
