import API from '@/service/api'
import authHeader from '@/service/auth-header'

const state = {
  attachmentsEntries: [],
  attachmentsTypesEntries: [],
  attachmentEntry: {},
}

const mutations = {
  UPDATE_ATTACHMENT_ENTRIES(state, payload) {
    state.attachmentsEntries = payload
  },
  UPDATE_ATTACHMENT_TYPES_ENTRIES(state, payload) {
    state.attachmentsTypesEntries = payload
  },
  UPDATE_ATTACHMENT_ENTRY(state, payload) {
    state.attachmentEntry = payload
  },
}

const actions = {
  getAttachmentsEntries({ commit }) {
    return API()
      .get('/attachements', { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ATTACHMENT_ENTRIES', response.data)
      })
  },
  getAttachmentById({ commit }, id) {
    return API()
      .get(`/attachements/${id}`, { headers: authHeader() })

      .then(response => {
        commit('UPDATE_ATTACHMENT_ENTRY', response.data)
      })
  },
  getAttachmentsTypesEntries({ commit }) {
    return API()
      .get('/attachment-types', { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ATTACHMENT_TYPES_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getAttachmentsTypesEntries')
        console.log(error.response.data)
        return error
      })
  },

  addAttachmentType({ commit }, payload) {
    return API()
      .post('/attachment-types', payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ATTACHMENT_TYPES_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addAttachmentType')
        console.log(error.response.data)
        return error
      })
  },

  deleteAttachmentType({ commit }, id) {
    return API()
      .delete(`/attachment-types/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ATTACHMENT_TYPES_ENTRIES', response.data)
        console.log('OK: deleteAttachmentType')
        return response.data
      })
      .catch(error => {
        console.log('Error: deleteAttachmentType')
        console.log(error.response.data)
        return error
      })
  },

  updateAttachmentType({ commit }, payload) {
    return API()
      .put(`/attachment-types/${payload.id}`, payload, {
        headers: authHeader(),
      })
      .then(response => {
        commit('UPDATE_ATTACHMENT_TYPES_ENTRIES', response.data)
        console.log('OK: updateAttachmentType')
        return response.data
      })
      .catch(error => {
        console.log('Error: updateAttachmentType')
        console.log(error.response.data)
        return error
      })
  },

  addAttachment({ commit }, payload) {
    return API()
      .post('/attachements', payload, { headers: authHeader() })
      .then(response => {
        console.log('SUCCESS!!')
        commit('UPDATE_ATTACHMENT_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addAttachment')
        console.log(error.response.data)
        return error
      })
  },

  updateAttachment({ commit }, payload) {
    return API()
      .put(`/attachements/${payload.id}`, payload, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ATTACHMENT_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: updateAttachment')
        console.log(error.response.data)
        return error
      })
  },

  deleteAttachment({ commit }, id) {
    API()
      .delete(`/attachements/${id}`, { headers: authHeader() })
      .then(response => {
        commit('UPDATE_ATTACHMENT_ENTRIES', response.data).catch(error => {
          console.error(error.response.data)
        })
      })
  },
}

const getters = {
  attachementsEntries: state => state.attachmentsEntries,
  attachmentById: state => id =>
    state.attachmentsEntries.find(attachmentEntry => attachmentEntry.id === id),
  getAttachmentsTypes: state => state.attachmentsTypesEntries,
  getAttachmentsTypesById: state => id =>
    state.attachmentsTypesEntries.find(
      attachmentType => attachmentType.id === id
    ),
}

const attachmentModule = {
  state,
  mutations,
  actions,
  getters,
}

export default attachmentModule
